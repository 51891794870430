import { getFallbackImageUrl } from '@msdyn365-commerce-modules/retail-actions';
import { Button, INodeProps } from '@msdyn365-commerce-modules/utilities';
import { PriceComponent } from '@msdyn365-commerce/components';
import { ICoreContext, IImageSettings, Image, ITelemetry } from '@msdyn365-commerce/core';
import { ProductListLine } from '@msdyn365-commerce/retail-proxy';
import { ProductPrice, SimpleProduct } from '@msdyn365-commerce/retail-proxy/dist/Entities/CommerceTypes.g';
import React, { useState } from 'react';
import { IOrderTemplateLineViewModel } from '../order-template';
import OrderTemplateQuantity from './common/order-template-quantity';

export interface IOrderTemplateLineProps extends ProductListLine {
    orderTemplateLine: IOrderTemplateLineViewModel;
    productListLine: ProductListLine;
    price?: ProductPrice;
    context: ICoreContext;
    productUrl: string;
    imageSettings?: IImageSettings;
    telemetry: ITelemetry;
    moduleId: string;
    moduleTypeName: string;
    quantity: number;
    handlers: {
        onAddToBag(product: SimpleProduct, quantity: number, itemKey: string): void;
        onRemoveItem(line: IOrderTemplateLineViewModel): void | undefined;
        onChangeQuantity(orderTemplateLineId: string, quantity: number, props?: IOrderTemplateLineProps): void;
        onSelect(line: IOrderTemplateLineViewModel, isChecked: boolean): void;
    };
    resources: {
        productDimensionTypeColor: string;
        productDimensionTypeSize: string;
        productDimensionTypeStyle: string;
        originalPriceText: string;
        currentPriceText: string;
        freePriceText: string;
        inputQuantityAriaLabel: string;
        removeFromOrderTemplateLinesText: string;
        addToBagButtonText: string;
        deleteButtonText: string;
    };
    index: number;
    isMobile: boolean;
    productQuantityView?: React.ReactNode;
    addToBagButton?: React.ReactNode;
    removeButton?: React.ReactNode;
    toggleDetailsDisplayed(props: IOrderTemplateLineProps): void;
}

export interface IOrderTemplateLineViewProps {
    key?: string;
    productImage: React.ReactNode;
    productPrice?: React.ReactNode;
    addToBagButton?: React.ReactNode;
    removeButton?: React.ReactNode;
    productName?: string;
    productNumber?: React.ReactNode;
    productInfo?: React.ReactNode;
    productLabel?: React.ReactNode;
    productUnitOfMeasure?: React.ReactNode;
    productQuantity?: number;
    productQuantityView?: React.ReactNode;
    productListId?: string;
    selectLine?: React.ReactNode;
    popUp?: JSX.Element;
    isMobile?: boolean;
}

interface IEventHandlerFactory {
    getAddToBag(): (event: React.MouseEvent<HTMLElement>) => void;
    getRemoveItem(): (event: React.MouseEvent<HTMLElement>) => void;
}

const OrderTemplateLineActions = (props: IOrderTemplateLineProps): IEventHandlerFactory => {
    const { orderTemplateLine, productListLine, quantity, handlers } = props;
    const { LineId } = productListLine;
    const { onAddToBag, onRemoveItem } = handlers;

    return {
        getAddToBag: () => (event: React.MouseEvent<HTMLElement>) => LineId && orderTemplateLine.fullProduct && onAddToBag(orderTemplateLine.fullProduct.ProductDetails, quantity, LineId),
        getRemoveItem: () => (event: React.MouseEvent<HTMLElement>) => LineId && onRemoveItem(props.orderTemplateLine)
    };
};

interface IPopupProps extends IOrderTemplateLineProps {
    addButtonClassName: string;
    removeButtonClassName: string;
}

const PopUp = (props: IPopupProps): JSX.Element | null => {
    const { addToBagButtonText, removeFromOrderTemplateLinesText } = props.resources;
    const [ showMobile, setShowMobile ] = useState(false);
    const componentClassName = 'ms-table';

    const getViewHandler = () => (event: React.MouseEvent<HTMLElement>) => {
        props.toggleDetailsDisplayed(props);
    };

    const toggle = (event: React.MouseEvent) => {
        setShowMobile(!showMobile);
    };
    const viewTitle = 'View product details';

    return (
        <div className={`${componentClassName}__row-links-minified`}>
            {<Button className={`${componentClassName}__row-links-toggle`} data-type={props.index} onClick={toggle}/>}
            {showMobile &&
            <div className={`${componentClassName}__row-links`}>
                <Button
                    className='ms-table__row-links-view'
                    onClick={getViewHandler()}
                    title={viewTitle}
                >View
                </Button>
                {getActionButton(true, props.removeButtonClassName, props.isMobile ? removeFromOrderTemplateLinesText : '', OrderTemplateLineActions(props).getRemoveItem())}
                {getActionButton(true, props.addButtonClassName, props.isMobile ? addToBagButtonText : '', OrderTemplateLineActions(props).getAddToBag())}
             </div>}
        </div>
    );
};

const getActionButton = (isMobile: boolean, className: string, buttonText: string, clickHandler: (event: React.MouseEvent<HTMLElement>) => void): React.ReactNode => (
    <Button
        className={className}
        onClick={clickHandler}
        title={buttonText}
    >{buttonText}
    </Button>
);

export const OrderTemplateLineView = (input: IOrderTemplateLineProps): IOrderTemplateLineViewProps | null => {
    const { orderTemplateLine, productListLine, price, context, imageSettings, quantity, resources, isMobile } = input;
    const product = orderTemplateLine.fullProduct;
    const { LineId, UnitOfMeasure } = productListLine;
    const { removeFromOrderTemplateLinesText, addToBagButtonText } = resources;
    if (!product) {
        return null;
    }

    const onSelectLine = (event: React.ChangeEvent<HTMLInputElement>) => {
        orderTemplateLine.isChecked = !orderTemplateLine.isChecked;

        input.handlers.onSelect(orderTemplateLine, event.target.checked);
    };
    const { ItemId, Name, PrimaryImageUrl } = product.ProductDetails;
    const fallbackImage = getFallbackImageUrl(ItemId, context.actionContext.requestContext.apiSettings);
    const addButtonClassName = input.isMobile ? 'ms-table__row-links-add' : 'ms-order-template-table-line__product-add-button';
    const removeButtonClassName = input.isMobile ? 'ms-table__row-links-delete' : 'ms-order-template-table-line__product-remove-button';

    return {
        isMobile: isMobile,
        selectLine: (
            <label className='checkbox-container'>
                <input type='checkbox' checked={orderTemplateLine.isChecked} aria-checked={orderTemplateLine.isChecked} onChange={onSelectLine}/>
                <span className='checkmark'/>
            </label>
        ),
        key: LineId!,
        productImage: PrimaryImageUrl && Name && (
            _renderProductImage('ms-order-template-table-line__product-image', context, product. ProductDetails.Name!, PrimaryImageUrl, fallbackImage, imageSettings)
        ),
        productInfo: renderProductInfo(input),
        productPrice: price && _renderPrice(input),
        productNumber: product && <div className='ms-order-template-table-line__product-number'>{ItemId}</div>,
        productUnitOfMeasure: price ? (
            <div className='ms-order-template-table-line__product-unit-of-measure'>{UnitOfMeasure || price.UnitOfMeasure}</div>
        ) : (
            undefined
        ),
        productListId: LineId!,
        removeButton: getActionButton(true, removeButtonClassName, input.isMobile ? removeFromOrderTemplateLinesText : '', OrderTemplateLineActions(input).getRemoveItem()),
        productQuantityView: <div>{_generateQuantity(input, LineId!)}</div>,
        productQuantity: quantity,
        addToBagButton: getActionButton(true, addButtonClassName, input.isMobile ? addToBagButtonText : '', OrderTemplateLineActions(input).getAddToBag()),
        popUp: (
            <PopUp
                {...input}
                addButtonClassName={addButtonClassName}
                removeButtonClassName={removeButtonClassName}
            />
        )
    };
};

export const OrderTemplateMobileLineView = (input: IOrderTemplateLineProps): IOrderTemplateLineViewProps | undefined => {
    const { orderTemplateLine, productListLine, price, context, imageSettings } = input;
    const product = orderTemplateLine.fullProduct;
    const { LineId, UnitOfMeasure } = productListLine;
    if (!product) {
        return;
    }
    const { ItemId, Name, PrimaryImageUrl } = product.ProductDetails;
    const fallbackImage = getFallbackImageUrl(ItemId, context.actionContext.requestContext.apiSettings);

    return {
        productImage: PrimaryImageUrl && Name && (
            _renderProductImage('thumbnail', context, product. ProductDetails.Name!, PrimaryImageUrl, fallbackImage, imageSettings)
        ),
        productInfo: renderProductInfo(input),
        productPrice: price && _renderPrice(input),
        productNumber: ItemId,
        productUnitOfMeasure: UnitOfMeasure || price?.UnitOfMeasure,
        productQuantityView: _generateQuantity(input, LineId!)
    };
};

export interface IOrderTemplateProductQuantityViewProps {
    ContainerProps: INodeProps;
    input: React.ReactNode;
}

const _generateQuantity = (props: IOrderTemplateLineProps, itemKey: string): React.ReactNode => {
    const { handlers, resources, quantity } = props;
    const { onChangeQuantity } = handlers;
    const onChange = (newValue: number) => {
        if (onChangeQuantity) {
            onChangeQuantity(itemKey, newValue, props);
        }
    };
    return (
        <OrderTemplateQuantity
            currentCount={quantity}
            onChange={onChange}
            max={Number.MAX_VALUE}
            inputQuantityAriaLabel={resources.inputQuantityAriaLabel}
        />
    );
};

export const _renderProductImage = (
    className: string,
    context: ICoreContext,
    heading: string,
    image?: string,
    fallbackImage?: string,
    imageSettings?: IImageSettings
): JSX.Element | null => {
    const defaultImageSettings: IImageSettings = {
        viewports: {
            xs: { q: `w=64&h=64&m=6`, w: 0, h: 0 },
            lg: { q: `w=64&h=64&m=6`, w: 0, h: 0 },
            xl: { q: `w=64&h=64&m=6`, w: 0, h: 0 }
        },
        lazyload: true
    };

    if (image) {
        return (
            <Image
                className={className}
                altText={heading}
                title={heading}
                src={image}
                fallBackSrc={fallbackImage}
                gridSettings={context.request.gridSettings!}
                imageSettings={imageSettings || defaultImageSettings}
                loadFailureBehavior='empty'
            />
        );
    }
    return null;
};

const _renderPrice = (props: IOrderTemplateLineProps): JSX.Element | null => {
    const { price, context, moduleId, moduleTypeName, resources } = props;

    if (price) {
        return (
            <PriceComponent
                id={moduleId}
                typeName={moduleTypeName}
                data={{ price: price }}
                className='ms-order-template-table-line__product-price'
                freePriceText={resources.freePriceText}
                originalPriceText={resources.originalPriceText}
                currentPriceText={resources.currentPriceText}
                context={context}
            />
        );
    }
    return null;
};

const _renderProductDimensions = (props: IOrderTemplateLineProps): (string | undefined)[] => {
    const { orderTemplateLine } = props;

    if (!orderTemplateLine.fullProduct || !orderTemplateLine.fullProduct.ProductDetails.Dimensions) {
        return [];
    }

    return orderTemplateLine.fullProduct.ProductDetails.Dimensions.map((dimension) => {
        if (!dimension.DimensionValue || !dimension.DimensionValue.Value) {
            return undefined;
        }

        return dimension.DimensionValue.Value;
    });
};

export const renderProductInfo = (props: IOrderTemplateLineProps): React.ReactNode | undefined => {
    const { orderTemplateLine, productUrl } = props;
    const name = orderTemplateLine.fullProduct?.ProductDetails.Name;

    const dimensions = _renderProductDimensions(props);
    if (!orderTemplateLine.fullProduct) {
        return [];
    }

    const productName = productUrl ? (
        <a className='ms-order-template-table-line__product-info-name' href={productUrl}>
            {name}
        </a>
    ) : (
        <div className='ms-order-template-table-line__product-info-name'>{name}</div>
    );

    const dimensionsLine: string = dimensions.join(', ');

    return (
        <div className='ms-order-template-table-line__product-info'>
            <div className='ms-order-template-table-line__product-info-name'>{productName}</div>
            <div className='ms-order-template-table-line__product-info-dimension'>
                {dimensionsLine}
            </div>
        </div>
    );
};

import {
    buildCacheKeyWithUrlTokens,
    getFullOrderTemplate,
    GetFullOrderTemplateInput,
    IQueryResultSettingsModuleConfig,
    OrderTemplate,
    QueryResultSettingsProxy
} from '@msdyn365-commerce-modules/retail-actions';
import {
    CacheType,
    createObservableDataAction,
    IAction,
    IActionContext,
    IActionInput,
    ICreateActionContext,
    IRequestContext
} from '@msdyn365-commerce/core';
import { IQueryResultSettings } from '@msdyn365-commerce/retail-proxy';

/**
 * Input class for getActiveOrderTemplateLines data action
 */
export class ActiveOrderTemplateLinesInput implements IActionInput {
    public requestContext: IRequestContext;
    public orderTemplateId: string;
    public queryResultSettings: IQueryResultSettings;

    constructor(requestContext: IRequestContext, config: IQueryResultSettingsModuleConfig) {
        if (!requestContext.query?.id) {
            throw new Error('Failed to get order template id from the query.');
        }
        if (!config) {
            throw new Error('Failed to get order template module configuration');
        }
        this.orderTemplateId = requestContext.query.id;
        this.queryResultSettings = QueryResultSettingsProxy.fromModuleData(requestContext, config).QueryResultSettings;

        this.queryResultSettings.count = true;
        this.requestContext = requestContext;
    }

    public getCacheKey = (): string =>  buildCacheKeyWithUrlTokens('ActiveOrderTemplateLines', this.requestContext);
    public getCacheObjectType = (): string => 'ActiveOrderTemplateLines';
    public dataCacheType = (): CacheType => 'instance';
    public shouldCacheOutput = () => false;
}

/**
 * Calls the Retail API and returns a ProductList object based on the passed GetCartInput
 */
export async function getActiveOrderTemplateLines(input: ActiveOrderTemplateLinesInput, context: IActionContext): Promise<OrderTemplate> {
    return getFullOrderTemplate(new GetFullOrderTemplateInput(input.orderTemplateId, input.queryResultSettings), context);
}

export const getActiveOrderTemplateLinesDataAction = createObservableDataAction({
    id: '@msdyn365-commerce-modules/order-template/order-template/get-order-template-lines',
    action: <IAction<OrderTemplate>>getActiveOrderTemplateLines,
    input: (context: ICreateActionContext) => {
        if (!context.config) {
            throw new Error('Failed to get order template module configuration');
        }
        return new ActiveOrderTemplateLinesInput(context.requestContext, context.config);
    }
});

export default getActiveOrderTemplateLinesDataAction;